/*Router/Auth Stuff */
import {Routes, Route} from "react-router-dom"
/*Pages */
import { Home} from "./pages/Home";



/*CSS */
import './pages/main.css'
import './pages/contact.css'
import './pages/home.css'
import './pages/pricing.css'



function App() {

  return (  
    <>  
        <div className="siteGrid">
          <div >
            <Routes>
              <Route path='*' element={<Home/>} />
              <Route path='/' element={<Home/>} />

            </Routes>
          </div>
        </div>
        
    </>
  )    

}
export default App;
